/* ------------------------------------ */
/* - Default variables - */
/* ------------------------------------ */

//-- Primary Colors
$rot: #ac0000;
$torfgrau: #333333;
$carbon-grey: #2B2E39;
$emerald-city: #20AEBD;
$deep-sea-baby: #1F2246;

//-- Secondary Colors
$clay-court: #E27234;
$generali-red: #C21B17;

//-- Basic Colors
$black: #000000;
$white: #FFFFFF;

//-- Grey Colors
$marble-grey: #EFEFEF;
$pebble-grey: #D9D9D9;
$felt-grey: #B5B5B5;
$soot-grey: #4C4C4C;
$haze-grey: #F6F6F6;
$testimonial-grey: #F3F3F3;
$touch-grey: #F9F9F9;

//-- Basic font sizes
$font-size-1: 1.125rem; // 18px
$font-height-1: 1.4444; // 26px
$font-size-2: 1rem; // 16px
$font-height-2: 1.5; // 24px
$font-size-3: 0.875rem; // 14px
$font-height-3: 1.4286; // 20px
$font-size-4: 0.75rem; // 12px
$font-height-4: 1.5; // 18px
$font-button: 1rem; // 16px
$font-button-height: 1.125; // 18px
$font-date: 0.75rem; // 12px
$font-date-height: 1.5; // 18px

// Basic mobile font sizes
$font-size-1-mobile: 1rem; // 16px
$font-height-1-mobile: 1.5; // 24px
$font-size-1-b-mobile: 0.875rem; // 14px
$font-height-1-b-mobile: 1.429; // 20px
$font-size-2-mobile: 1rem; // 16px
$font-height-2-mobile: 1.5; // 24px
$font-size-3-mobile: 1rem; // 16px
$font-height-3-mobile: 1.5; // 24px
$font-size-4-mobile: 0.75rem; // 12px
$font-height-4-mobile: 1.5; // 18px
$font-button-mobile: 0.875rem; // 14px
$font-button-height-mobile: 1.429; // 20px

//-- Title font sizes
$font-title-size-0: 6.25rem; // 100px
$font-title-height-0: 1; // 100px
$font-title-size-1: 4.375rem; // 70px
$font-title-height-1: 1.0857; // 76px
$font-title-size-2: 4rem; // 64px
$font-title-height-2: 1.09375; // 70px
$font-title-size-3: 3rem; // 48px
$font-title-height-3: 1.041666; // 50px
$font-title-size-4: 2.625rem; // 42px
$font-title-height-4: 1.190476; // 50px
$font-title-size-5: 2rem; // 32px
$font-title-height-5: 1.125; // 36px
$font-title-size-6: 1.625rem; // 26px
$font-title-height-6: 1.3077; // 34px
$font-title-size-7: 1.5rem; // 24px
$font-title-height-7: 1.3333; // 32px
$font-title-size-8: 1.375rem; // 22px
$font-title-height-8: 1.4545; // 32px
$font-flagtitle-size: 1.25rem; // 20px
$font-flagtitle-height: 1.2; // 24px

//-- Title mobile font sizes
$font-title-size-0-mobile: $font-title-size-3; // 48px
$font-title-height-0-mobile: $font-title-height-3; // 50px
$font-title-size-1-mobile: $font-title-size-4; // 42px
$font-title-height-1-mobile: $font-title-height-4; // 50px
$font-title-size-2-mobile: $font-title-size-4; // 42px
$font-title-height-2-mobile: $font-title-height-4; // 50px
$font-title-size-3-mobile: $font-title-size-5; // 32px
$font-title-height-3-mobile: $font-title-height-5; // 36px
$font-title-size-4-mobile: $font-title-size-6; // 26px
$font-title-height-4-mobile: $font-title-height-6; // 34px
$font-title-size-5-mobile: $font-title-size-6; // 26px
$font-title-height-5-mobile: $font-title-height-6; // 34px
$font-title-size-6-mobile: $font-title-size-8; // 22px
$font-title-height-6-mobile: $font-title-height-8; // 32px
$font-title-size-7-mobile: $font-title-size-8; // 22px
$font-title-height-7-mobile: $font-title-height-8; // 32px
$font-title-size-8-mobile: $font-flagtitle-size; // 20px
$font-title-height-8-mobile: $font-flagtitle-height; // 24px
$font-flagtitle-size-mobile: 1.125rem; // 18px
$font-flagtitle-height-mobile: 1.4444; // 26px

//-- Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;

//-- Base Font
$base-font-size: 16px;
$base-font-height: 1.5;

//-- Title Font
$title-font-weight: $font-weight-regular;
$font-title-size-1-weight: $font-weight-light;
$font-title-size-2-weight: $font-weight-regular;
$font-title-size-3-weight: $font-weight-semi-bold;
$font-title-size-4-weight: $font-weight-semi-bold;
$font-title-size-5-weight: $font-weight-regular;
$font-title-size-6-weight: $font-weight-regular;

//-- Spacing
$gap: 1rem; // 16px

//-- Responsivity
$breakpoint-x-small: 420px;
$breakpoint-small: 768px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1200px;

//-- Grid
$grid-column: 6.173%;
$grid-gap: 2.16%;
$grid-half-gap: 1.08%;

/* ------------------------------------ */
/* - Component variables - */
/* ------------------------------------ */

//-- Image
$image-placeholder-background: $pebble-grey;

//-- Video
$video-controls-color: $white;
$video-controls-active-color: $rot;
$video-text-color: $black;
$video-border-color: $black;

//-- Container
$container-limited-width: 120rem; // 1920px

//-- Content offset
$content-offset: 5%;
$content-offset-mobile: 4%;

//-- Link
$link-color: $black;
$link-color-hover: $soot-grey;
$link-color-disabled: $felt-grey;
$link-decoration: underline;
$link-decoration-hover: none;

//-- Text
$color-text: $soot-grey;
$color-text-inverted: $white;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

//-- Title
$title-font-color: $black;
$title-margin-top: $gap * 2;
$title-margin-bottom: $gap;
$title-uppercase: false;

//-- Table
$table-border-color: $pebble-grey;
$table-th-border-color: $black;

//-- Buttons
$button-font-weight: $font-weight-semi-bold;
$button-border-radius: 2rem;
$button-outline: $black;

$button-font-size: $font-button;
$button-font-height: $font-button-height;
$button-padding: 1.0625rem 1.9375rem;
$button-padding-bordered: 1rem 1.875rem;
$button-min-height: 3.25rem;
$button-margin: 1.5em;

$button-font-size-medium: $font-button-mobile;
$button-font-height-medium: $font-button-height-mobile;
$button-padding-medium: 0.625rem 1.5625rem;
$button-padding-bordered-medium: 0.5625rem 1.5rem;
$button-min-height-medium: 2.5rem;
$button-margin-medium: 1.4444em;

$buttons: (
  "primary": (
    "bg-color": $rot,
    "font-color": $white,
    "icon-color": $white,
    "border-color": $rot,
    "hover-bg-color": $carbon-grey,
    "hover-font-color": $white,
    "hover-icon-color": $white,
    "hover-border-color": $carbon-grey,
    "disabled-bg-color": $marble-grey,
    "disabled-font-color": $pebble-grey,
    "disabled-border-color": $marble-grey,
    "active-font-color": $rot,
  ),
  "primary-inverted": (
    "bg-color": $white,
    "font-color": $black,
    "icon-color": $black,
    "border-color": $white,
    "hover-bg-color": $carbon-grey,
    "hover-font-color": $white,
    "hover-icon-color": $white,
    "hover-border-color": $carbon-grey,
    "disabled-bg-color": $marble-grey,
    "disabled-font-color": $pebble-grey,
    "disabled-border-color": $marble-grey,
    "active-font-color": $rot,
  ),
  "secondary": (
    "bg-color": $color-transparent,
    "font-color": $black,
    "icon-color": $black,
    "border-color": $carbon-grey,
    "hover-bg-color": $carbon-grey,
    "hover-font-color": $white,
    "hover-icon-color": $white,
    "hover-border-color": $carbon-grey,
    "disabled-bg-color": $color-transparent,
    "disabled-font-color": $pebble-grey,
    "disabled-border-color": $pebble-grey,
    "active-font-color": $rot,
  ),
  "secondary-inverted": (
    "bg-color": $color-transparent,
    "font-color": $white,
    "icon-color": $white,
    "border-color": $white,
    "hover-bg-color": $white,
    "hover-font-color": $carbon-grey,
    "hover-icon-color": $carbon-grey,
    "hover-border-color": $white,
    "disabled-bg-color": $color-transparent,
    "disabled-font-color": $pebble-grey,
    "disabled-border-color": $pebble-grey,
    "active-font-color": $rot,
  ),
  "tertiary": (
    "bg-color": $rot,
    "font-color": $white,
    "icon-color": $white,
    "border-color": $rot,
    "hover-bg-color": $carbon-grey,
    "hover-font-color": $white,
    "hover-icon-color": $white,
    "hover-border-color": $carbon-grey,
    "disabled-bg-color": $marble-grey,
    "disabled-font-color": $pebble-grey,
    "disabled-border-color": $marble-grey,
    "active-font-color": $rot,
  ),
);

//-- Forms
$input-font-color: $black;
$input-font-color-disabled: $felt-grey;
$input-font-size: $font-button;
$input-line-height: $font-button-height;
$input-bg-color: $haze-grey;
$input-bg-color-disabled: $marble-grey;
$input-border-color: $haze-grey;
$input-border-color-focus: $rot;
$input-border-color-disabled: $marble-grey;
$input-border-radius: 3rem;
$input-padding: 0.875rem 1.5rem;
$input-padding-mobile: 0.5625rem 1.125rem;
$input-placeholder-color: $soot-grey;
$input-placeholder-color-disabled: $pebble-grey;

//-- Select
$t-select-font-color: $black;
$t-select-border-color: $haze-grey;
$t-select-color-input: $haze-grey;
$t-select-color-disabled: $felt-grey;
$t-select-color-item: $haze-grey;
$t-select-color-item-text: $soot-grey;
$t-select-color-item-active: $haze-grey;
$t-select-color-item-active-text: $black;
$t-select-color-dropdown: $haze-grey;
$t-select-color-dropdown-item-active-text: $black;
$t-select-active-bolder-color: $rot;
$t-select-icon-color: $black;
$t-select-option-color: $soot-grey;
$t-select-active-option-color: $black;
$t-select-active-option-icon-color: $black;
$t-select-save-label-background-color: $rot;
$t-select-save-label-color: $white;
$t-select-save-label-icon-color: $white;
$t-select-touched-background-color: $black;
$t-select-touched-color: $white;
$t-select-touched-icon-color: $white;

//-- Alerts
$alert-font-color: $black;
$alert-font-color-success: $emerald-city;
$alert-font-color-error: $generali-red;
$alert-background: $white;
$alert-background-success: $white;
$alert-background-error: $white;
$alert-font-size: $font-size-1;
$alert-font-height: $font-height-1;
$alert-padding: 1.25rem 1.5rem;
$alert-border: 1px solid $haze-grey;
$alert-border-success: 1px solid $emerald-city;
$alert-border-error: 1px solid $generali-red;
$alert-border-radius: 0;
$alert-margin: 0 0 1.5rem;

//-- Tags
$tag-background: $marble-grey;
$tag-font-color: $black;
$tag-active-background: $rot;
$tag-active-font-color: $white;
$tag-disabled-background: $haze-grey;
$tag-disabled-font-color: $soot-grey;
$tag-outline: $black;
$tag-border-radius: 2rem;

$tag-font-size: $font-button;
$tag-font-height: $font-button-height;
$tag-padding: 0.625rem 3rem 0.75rem 1.875rem;
$tag-height: 2.5rem;
$tag-margin: 0.75rem;

$tag-font-size-medium: $font-button-mobile;
$tag-font-height-medium: $font-button-height-mobile;
$tag-padding-medium: 0.4375rem 2.625rem 0.5625rem 1.5rem;
$tag-height-medium: 2.25rem;
$tag-margin-horizontal-medium: 0.375rem;
$tag-margin-vertical-medium: 0.625rem;

$tag-search-background: $marble-grey;
$tag-search-border-color: $pebble-grey;
$tag-search-font-color: $black;
$tag-search-icon-color: $tag-search-font-color;
$tag-search-icon-background-color: $tag-search-background;
$tag-search-active-background: $marble-grey;
$tag-search-active-border-color: $tag-search-border-color;
$tag-search-active-font-color: $black;
$tag-search-active-icon-color: $tag-search-active-font-color;
$tag-search-active-icon-background-color: $tag-search-background;
$tag-search-hover-background: $tag-search-active-background;
$tag-search-hover-border-color: $tag-search-border-color;
$tag-search-hover-font-color: $tag-search-font-color;
$tag-search-hover-icon-color: $tag-search-hover-font-color;
$tag-search-hover-icon-background-color: $tag-search-background;
$tag-search-active-hover-background: $tag-search-active-background;
$tag-search-active-hover-border-color: $tag-search-border-color;
$tag-search-active-hover-font-color: $tag-search-font-color;
$tag-search-active-hover-icon-color: $tag-search-hover-font-color;
$tag-search-active-hover-icon-background-color: $tag-search-background;

//-- Switch
$switch-width: 3.125rem;
$switch-height: 1.875rem;
$switch-width-medium: 2.5rem;
$switch-height-medium: 1.5rem;
$switch-radius: 6.25rem;
$switch-background: $white;
$switch-background-active: $white;
$switch-background-disabled: $marble-grey;
$switch-color: $soot-grey;
$switch-color-active: $rot;
$switch-border: $switch-color;
$switch-border-active: $switch-color-active;

//-- Tooltip
$tooltip-background: $torfgrau;
$tooltip-font-color: $white;

//-- Header
$header-background: $white;
$header-logo-right-offset: 1rem;
$header-logo-right-offset-medium: 0.625rem;
$header-logo-right-offset-small: 0.5rem;
$header-logo-bottom-offset: 0;
$header-logo-bottom-offset-medium: 0;
$header-logo-bottom-offset-small: 0;
$header-logo-text-color: $black;
$header-logo-text-left-offset: 0.8125rem;
$header-logo-text-left-offset-medium: 0.5rem;
$header-logo-text-left-offset-small: 0.375rem;
$header-logo-text-bottom-offset: 0.25rem;
$header-logo-text-bottom-offset-medium: 0.1875rem;
$header-logo-text-bottom-offset-small: 0.125rem;
$header-logo-line-color: $pebble-grey;
$header-generic-background: $haze-grey;
$header-generic-font-color: $torfgrau;
$header-generic-secondary-font-color: $felt-grey;
$header-generic-secondary-icon-color: $black;
$header-generic-secondary-icon-active-color: $rot;
$header-main-font-color: $carbon-grey;
$header-main-active-color: $rot;
$header-main-border-color: $pebble-grey;
$header-main-title-color: $soot-grey;
$header-main-submenu-color: $torfgrau;
$header-main-icon-color: $black;
$header-main-mobile-menu-color: $torfgrau;
$header-main-mobile-menu-icon-color: $rot;
$header-main-mobile-menu-icon-shape-color: $white;
$header-auth-font-color: $torfgrau;
$header-auth-font-unactive-color: $felt-grey;
$header-auth-label-color: $black;
$header-auth-icon-color: $black;
$header-auth-icon-active-color: $rot;
$header-auth-button-type: "tertiary";
$header-search-overlay-background: $rot;
$header-search-overlay-color: $white;
$header-search-color: $soot-grey;
$header-search-placeholder-color: $soot-grey;
$header-search-autocomplete-color: $pebble-grey;
$header-search-top-line-color: $rot;
$header-search-icon-color: $black;
$header-search-suggestion-color: $soot-grey;
$header-nav-overlay-lv-background: $carbon-grey;
$header-nav-overlay-lv-color: $white;
$header-nav-overlay-lv-button-color: $white;
$header-nav-overlay-lv-item-background: $white;
$header-nav-overlay-lv-item-color: $black;
$header-nav-overlay-lv-item-mobile-color: $white;
$header-nav-overlay-lv-item-line-color: $felt-grey;
$header-nav-overlay-lv-close-icon-color: $white;
$header-nav-overlay-lv-close-icon-active-color: $rot;
$header-nav-overlay-lv-close-icon-line-mobile: #676767;
$header-logged-in-background: $haze-grey;
$header-logged-in-text-color: $black;
$header-logged-in-line-color: $pebble-grey;
$header-logged-in-active-line-color: $rot;
$header-logged-in-icon-color: $black;
$header-logged-in-overlay-background: $rot;
$header-logged-in-overlay-text-color: $white;
$header-mobile-log-in-background: $touch-grey;
$header-mobile-log-in-text-color: $carbon-grey;
$header-mobile-log-in-icon-color: $carbon-grey;
$header-mobile-log-in-background-active: $rot;
$header-mobile-log-in-text-color-active: $white;
$header-mobile-log-in-icon-color-active: $white;

//-- Search results
$search-results-background-color: $white;
$search-results-border-color: $pebble-grey;
$search-results-color: $black;
$search-results-placeholder-color: $felt-grey;
$search-results-autocomplete-color: $pebble-grey;
$search-results-icon-color: $black;
$search-results-icon-reset-color: $felt-grey;
$search-results-icon-active-color: $rot;
$search-results-text-active-color: $rot;
$search-results-breadcrumb-color: $soot-grey;
$search-results-breadcrumb-separator-color: $black;
$search-results-go-icon-color: $torfgrau;
$search-results-category-color: $black;
$search-results-type-color: $torfgrau;
$search-results-media-icon-color: $white;
$search-results-suggestion-color: $soot-grey;
$search-results-video-icon-color: $rot;
$search-results-top-tag-color: $rot;
$search-results-select-title-color: $soot-grey;
$search-results-active-federations-line-color: $pebble-grey;
$search-results-active-federations-icon-color: $black;
$search-results-select-reset-color: $soot-grey;

//-- Basic teaser
$basic-teaser-gray-background-color: $haze-grey;
$basic-teaser-gray-text-color: $black;
$basic-teaser-gray-link-color: $white;
$basic-teaser-gray-icon-color: $white;
$basic-teaser-primary-background-color: $rot;
$basic-teaser-primary-text-color: $white;
$basic-teaser-primary-link-color: $white;
$basic-teaser-primary-icon-color: $white;
$basic-teaser-secondary-background-color: $carbon-grey;
$basic-teaser-secondary-text-color: $white;
$basic-teaser-secondary-link-color: $white;
$basic-teaser-secondary-icon-color: $white;
$basic-teaser-tertiary-background-color: $rot;
$basic-teaser-tertiary-text-color: $white;
$basic-teaser-tertiary-link-color: $white;
$basic-teaser-tertiary-icon-color: $white;
$basic-teaser-quaternary-background-color: $torfgrau;
$basic-teaser-quaternary-text-color: $white;
$basic-teaser-quaternary-link-color: $white;
$basic-teaser-quaternary-icon-color: $white;
$basic-teaser-title-weight: $font-weight-regular;
$basic-teaser-title-uppercase: false;

//-- Image teaser
$image-teaser-title-color: $white;
$image-teaser-title-weight: $font-weight-regular;
$image-teaser-title-uppercase: false;

// -- Link list teaser
$link-list-teaser-background: $white;
$link-list-teaser-pretitle-color: $soot-grey;
$link-list-teaser-title-color: $black;
$link-list-teaser-title-weight: $font-weight-bold;
$link-list-teaser-title-uppercase: false;
$link-list-teaser-description-color: $soot-grey;
$link-list-teaser-description-hover-color: $torfgrau;
$link-list-teaser-icon-background: $torfgrau;
$link-list-teaser-icon-border: $link-list-teaser-icon-background;
$link-list-teaser-icon-color: $white;
$link-list-teaser-icon-background-hover: $link-list-teaser-icon-color;
$link-list-teaser-icon-border-hover: $link-list-teaser-icon-border;
$link-list-teaser-icon-color-hover: $link-list-teaser-icon-background;
$link-list-teaser-back-background: $rot;
$link-list-teaser-back-title-color: $white;
$link-list-teaser-back-link-color: $white;
$link-list-teaser-back-icon-background: $haze-grey;
$link-list-teaser-back-icon-border: $link-list-teaser-back-icon-background;
$link-list-teaser-back-icon-color: $rot;
$link-list-teaser-back-icon-background-hover: $link-list-teaser-back-icon-color;
$link-list-teaser-back-icon-border-hover: $link-list-teaser-back-icon-border;
$link-list-teaser-back-icon-color-hover: $link-list-teaser-back-icon-background;
$link-list-teaser-back-description-color: $haze-grey;
$link-list-teaser-back-description-hover-color: $white;
$link-list-teaser-secondary-background: $link-list-teaser-background;
$link-list-teaser-secondary-pretitle-color: $link-list-teaser-pretitle-color;
$link-list-teaser-secondary-title-color: $link-list-teaser-title-color;
$link-list-teaser-secondary-description-color: $link-list-teaser-description-color;
$link-list-teaser-secondary-description-hover-color: $link-list-teaser-description-hover-color;
$link-list-teaser-secondary-icon-background: $link-list-teaser-icon-background;
$link-list-teaser-secondary-icon-border: $link-list-teaser-icon-border;
$link-list-teaser-secondary-icon-color: $link-list-teaser-icon-color;
$link-list-teaser-secondary-icon-background-hover: $link-list-teaser-icon-background-hover;
$link-list-teaser-secondary-icon-border-hover: $link-list-teaser-icon-border-hover;
$link-list-teaser-secondary-icon-color-hover: $link-list-teaser-icon-color-hover;
$link-list-teaser-back-secondary-background: $link-list-teaser-back-background;
$link-list-teaser-back-secondary-title-color: $link-list-teaser-back-title-color;
$link-list-teaser-back-secondary-link-color: $link-list-teaser-back-link-color;
$link-list-teaser-back-secondary-icon-background: $link-list-teaser-back-icon-background;
$link-list-teaser-back-secondary-icon-border: $link-list-teaser-back-icon-border;
$link-list-teaser-back-secondary-icon-color: $link-list-teaser-back-icon-color;
$link-list-teaser-back-secondary-icon-background-hover: $link-list-teaser-back-icon-background-hover;
$link-list-teaser-back-secondary-icon-border-hover: $link-list-teaser-back-icon-border-hover;
$link-list-teaser-back-secondary-icon-color-hover: $link-list-teaser-back-icon-color-hover;
$link-list-teaser-back-secondary-description-color: $link-list-teaser-back-description-color;
$link-list-teaser-back-secondary-description-hover-color: $link-list-teaser-back-description-hover-color;
$link-list-teaser-tertiary-background: $link-list-teaser-background;
$link-list-teaser-tertiary-pretitle-color: $link-list-teaser-pretitle-color;
$link-list-teaser-tertiary-title-color: $link-list-teaser-title-color;
$link-list-teaser-tertiary-description-color: $link-list-teaser-description-color;
$link-list-teaser-tertiary-description-hover-color: $link-list-teaser-description-hover-color;
$link-list-teaser-tertiary-icon-background: $link-list-teaser-icon-background;
$link-list-teaser-tertiary-icon-border: $link-list-teaser-icon-border;
$link-list-teaser-tertiary-icon-color: $link-list-teaser-icon-color;
$link-list-teaser-tertiary-icon-background-hover: $link-list-teaser-icon-background-hover;
$link-list-teaser-tertiary-icon-border-hover: $link-list-teaser-icon-border-hover;
$link-list-teaser-tertiary-icon-color-hover: $link-list-teaser-icon-color-hover;
$link-list-teaser-back-tertiary-background: $link-list-teaser-back-background;
$link-list-teaser-back-tertiary-title-color: $link-list-teaser-back-title-color;
$link-list-teaser-back-tertiary-link-color: $link-list-teaser-back-link-color;
$link-list-teaser-back-tertiary-icon-background: $link-list-teaser-back-icon-background;
$link-list-teaser-back-tertiary-icon-border: $link-list-teaser-back-icon-border;
$link-list-teaser-back-tertiary-icon-color: $link-list-teaser-back-icon-color;
$link-list-teaser-back-tertiary-icon-background-hover: $link-list-teaser-back-icon-background-hover;
$link-list-teaser-back-tertiary-icon-border-hover: $link-list-teaser-back-icon-border-hover;
$link-list-teaser-back-tertiary-icon-color-hover: $link-list-teaser-back-icon-color-hover;
$link-list-teaser-back-tertiary-description-color: $link-list-teaser-back-description-color;
$link-list-teaser-back-tertiary-description-hover-color: $link-list-teaser-back-description-hover-color;

//-- Testimonial teaser
$testimonial-teaser-background: transparent;
$testimonial-teaser-text-color: $black;
$testimonial-teaser-title-color: $black;
$testimonial-teaser-title-weight: $font-weight-extra-bold;
$testimonial-teaser-title-uppercase: false;
$testimonial-teaser-pretitle-color: $black;
$testimonial-teaser-icon-color: $rot;
$testimonial-carousel-background: $testimonial-grey;
$testimonial-carousel-button-background: $rot;
$testimonial-carousel-pagination-background: $pebble-grey;
$testimonial-carousel-pagination-active: $rot;
$testimonial-carousel-pagination-color: $black;
$testimonial-carousel-pagination-grey-color: $felt-grey;

//-- Stage teaser
$stage-teaser-color: $white;
$stage-carousel-pagination-color: $white;
$stage-carousel-pagination-active-color: $rot;
$stage-carousel-icon-color: $white;
$stage-carousel-title-weight: $font-weight-regular;
$stage-carousel-title-uppercase: false;

//-- CTA teaser
$cta-teaser-background: $white;
$cta-teaser-text-color: $black;
$cta-teaser-title-color: $black;
$cta-teaser-title-weight: $font-weight-regular;
$cta-teaser-title-uppercase: false;
$cta-teaser-button-variant: "primary";
$cta-teaser-variant-2-background: $white;
$cta-teaser-variant-2-text-color: $black;
$cta-teaser-variant-2-title-color: $black;
$cta-teaser-variant-2-button-variant: "primary";
$cta-teaser-variant-2-button-secondary-variant: "secondary";

// Fact and figures teaser
$fact-figures-teaser-pretitle-color: $white;
$fact-figures-teaser-title-color: $white;
$fact-figures-teaser-title-weight: $font-weight-bold;
$fact-figures-teaser-title-uppercase: false;
$fact-figures-teaser-description-color: $white;
$fact-figures-teaser-description-weight: $font-weight-regular;
$fact-figures-teaser-description-uppercase: false;
$fact-figures-teaser-top-line-color: $white;
$fact-figures-teaser-highlighted-top-line-color: $white;
$fact-figures-teaser-button-variant: "secondary-inverted";
$fact-figures-carousel-line-color: $white;
$fact-figures-carousel-active-line-color: $white;
$fact-figures-carousel-text-color: $white;

//-- Topic teaser
$topic-teaser-white-background: $white;
$topic-teaser-white-text-color: $black;
$topic-teaser-white-text-link-color: $link-color;
$topic-teaser-white-text-link-hover-color: $link-color-hover;
$topic-teaser-white-button-variant: "secondary";
$topic-teaser-primary-background: $rot;
$topic-teaser-primary-text-color: $white;
$topic-teaser-primary-text-link-color: $white;
$topic-teaser-primary-text-link-hover-color: $white;
$topic-teaser-primary-button-variant: "secondary-inverted";
$topic-teaser-secondary-background: $carbon-grey;
$topic-teaser-secondary-text-color: $white;
$topic-teaser-secondary-text-link-color: $white;
$topic-teaser-secondary-text-link-hover-color: $white;
$topic-teaser-secondary-button-variant: "secondary-inverted";
$topic-teaser-tertiary-background: $rot;
$topic-teaser-tertiary-text-color: $white;
$topic-teaser-tertiary-text-link-color: $white;
$topic-teaser-tertiary-text-link-hover-color: $white;
$topic-teaser-tertiary-button-variant: "secondary-inverted";
$topic-teaser-quaternary-background: $torfgrau;
$topic-teaser-quaternary-text-color: $white;
$topic-teaser-quaternary-text-link-color: $white;
$topic-teaser-quaternary-text-link-hover-color: $white;
$topic-teaser-quaternary-button-variant: "secondary-inverted";
$topic-teaser-title-weight: $font-weight-regular;
$topic-teaser-title-uppercase: false;

//-- Top news
$top-news-topline-color: $black;
$top-news-topline-sub-color: $pebble-grey;
$top-news-title-color: $black;
$top-news-title-weight: $font-weight-semi-bold;
$top-news-title-uppercase: false;
$top-news-article-title-color: $black;
$top-news-article-title-weight: $font-weight-semi-bold;
$top-news-article-title-uppercase: false;
$top-news-article-length-color: $soot-grey;
$top-news-article-text-color: $soot-grey;
$top-news-article-tags-color: $black;
$top-news-article-tags-line-color: $soot-grey;
$top-news-article-image-info-color: $soot-grey;

//-- Top article
$top-article-topline-color: $black;
$top-article-topline-sub-color: $pebble-grey;
$top-article-main-tag-background: $white;
$top-article-title-color: $black;
$top-article-title-weight: $font-weight-semi-bold;
$top-article-title-uppercase: false;
$top-article-article-title-color: $black;
$top-article-article-title-weight: $font-weight-semi-bold;
$top-article-article-title-uppercase: false;
$top-article-article-text-color: $soot-grey;
$top-article-icon-background: $rot;

//-- People teaser
$people-teaser-item-background: $haze-grey;
$people-teaser-image-border: $carbon-grey;
$people-teaser-item-title-color: $black;
$people-teaser-item-text-color: $black;
$people-teaser-item-borders-color: $soot-grey;
$people-teaser-item-profile-border: $felt-grey;

//-- Intro text
$intro-text-color: $black;
$intro-text-strong-color: $rot;
$intro-text-weight: $font-weight-light;
$intro-text-strong-weight: $font-weight-semi-bold;
$intro-text-uppercase: false;
$intro-text-huge-text-color: $white;
$intro-text-huge-text-background: $rot;
$intro-text-huge-text-cursor-color: $black;
$intro-text-huge-text-cursor-background: $white;
$intro-text-huge-text-inverted-color: $rot;
$intro-text-huge-text-inverted-background: $white;
$intro-text-huge-text-inverted-cursor-color: $black;
$intro-text-huge-text-inverted-cursor-background: $rot;

//-- Container
$custom-container-background-white: $white;
$custom-container-background-gray: $pebble-grey;
$custom-container-text-gray: $black;
$custom-container-background-gray-secondary: $carbon-grey;
$custom-container-text-gray-secondary: $white;
$custom-container-background-quaternary: $torfgrau;
$custom-container-text-quaternary: $white;

//-- Custom container
$custom-container-background-white: $white;
$custom-container-title-white: $black;
$custom-container-background-gray: $pebble-grey;
$custom-container-title-gray: $black;
$custom-container-background-gray-secondary: $carbon-grey;
$custom-container-title-gray-secondary: $white;
$custom-container-background-quaternary: $torfgrau;
$custom-container-title-quaternary: $white;
$custom-container-background-gold: $white;
$custom-container-background-gold-mobile: $white;
$custom-container-text-gold: $black;
$custom-container-title-gold: $black;
$custom-container-title-weight: $font-weight-regular;
$custom-container-title-uppercase: false;

//-- Accordion
$accordion-button-color: $black;
$accordion-line-color: $pebble-grey;
$accordion-active-line-color: $black;
$accordion-icon-color: $torfgrau;
$accordion-icon-border-color: $torfgrau;
$accordion-icon-background-color: $white;
$accordion-active-icon-color: $white;
$accordion-active-icon-border-color: $rot;
$accordion-active-icon-background-color: $rot;
$accordion-hover-icon-color: $accordion-active-icon-color;
$accordion-hover-icon-border-color: $accordion-active-icon-border-color;
$accordion-hover-icon-background-color: $accordion-active-icon-background-color;
$accordion-container-gray-button-color: $black; // Gray container
$accordion-container-gray-line-color: $felt-grey;
$accordion-container-gray-active-line-color: $black;
$accordion-container-gray-icon-color: $torfgrau;
$accordion-container-gray-icon-border-color: $torfgrau;
$accordion-container-gray-icon-background-color: $custom-container-background-gray;
$accordion-container-gray-active-icon-color: $white;
$accordion-container-gray-active-icon-border-color: $rot;
$accordion-container-gray-active-icon-background-color: $rot;
$accordion-container-gray-secondary-button-color: $white; // Gray secondary container
$accordion-container-gray-secondary-line-color: $soot-grey;
$accordion-container-gray-secondary-active-line-color: $felt-grey;
$accordion-container-gray-secondary-icon-color: $felt-grey;
$accordion-container-gray-secondary-icon-border-color: $felt-grey;
$accordion-container-gray-secondary-icon-background-color: $custom-container-background-gray-secondary;
$accordion-container-gray-secondary-active-icon-color: $white;
$accordion-container-gray-secondary-active-icon-border-color: $rot;
$accordion-container-gray-secondary-active-icon-background-color: $rot;
$accordion-container-quaternary-button-color: $white; // Quaternary container
$accordion-container-quaternary-line-color: $soot-grey;
$accordion-container-quaternary-active-line-color: $felt-grey;
$accordion-container-quaternary-icon-color: $felt-grey;
$accordion-container-quaternary-icon-border-color: $felt-grey;
$accordion-container-quaternary-icon-background-color: $custom-container-background-quaternary;
$accordion-container-quaternary-active-icon-color: $white;
$accordion-container-quaternary-active-icon-border-color: $rot;
$accordion-container-quaternary-active-icon-background-color: $rot;

//-- Partner
$partner-topline-color: $black;
$partner-topline-sub-color: $pebble-grey;
$partner-text-color: $soot-grey;
$top-news-vertical-line-color: $black;

//-- Breadcrumbs
$breadcrumbs-text-color: $white;
$breadcrumbs-background-color: #22252E;

//-- List
$list-text-text-color: $black;
$list-block-line-color: $black;
$list-block-icon-background: $torfgrau;
$list-block-icon-color: $white;
$list-block-title-color: $black;
$list-block-title-weight: $font-weight-regular;
$list-block-title-uppercase: false;
$list-block-text-color: $black;
$list-block-link-color: $black;

//-- Download
$download-text-color: $black;
$download-icon-color: $black;
$download-icon-background: $marble-grey;
$download--button-type: "secondary";
$download-border-color: $marble-grey;
$download-icon-color: darken($rot, 5%);

//-- Image gallery
$image-gallery-text-color: $white;
$image-gallery-background-color: $torfgrau;
$image-gallery-border-color: $white;
$image-gallery-carousel-button-color: $white;
$image-gallery-carousel-button-active-color: $torfgrau;
$image-gallery-carousel-pagination-color: $white;
$image-gallery-carousel-pagination-line-color: $white;
$image-gallery-caption-weight: $font-weight-bold;
$image-gallery-caption-uppercase: false;

//-- Article template
$article-main-tag-color: $black;
$article-published-color: $soot-grey;
$article-line-color: $pebble-grey;
$article-more-info-color: $soot-grey;
$article-main-title-weight: $font-weight-light;
$article-main-title-uppercase: false;

//-- Overview template
$overview-template-icon-color: $white;
$overview-template-background: $white;

//-- Footer
$footer-background-color: $carbon-grey;
$footer-text-color: $white;
$footer-link-color: $white;
$footer-icon-color: $white;
$footer-input-active-color: $rot;
$footer-sub-background-color: #22252E;
$footer-sub-text-color: $white;
$footer-sub-link-color: $white;

//-- Logo wall
$logo-wall-background-color: $carbon-grey;
$logo-wall-text-color: #BFC0C3;
$logo-wall-line-color: #4B4D57;
$logo-wall-uppercase: false;

//-- Register teaser
$register-teaser-background-color: #F6F6F8;
$register-teaser-text-color: $black;

//-- Newsletter teaser
$newsletter-teaser-text-color: $white;
$newsletter-teaser-button-variant: "primary";

//-- Presenter
$presenter-line-color: #EBEBEB;

//-- Global cursor
$cursor-color: $black;
$cursor-background: $white;
$cursor-active-color: $white;
$cursor-active-background: $carbon-grey;

//-- Component Colors
$color-background: #ffffff;
$color-foreground: #000000;
$color-accent: #2954ff;
$color-accent-lighter: #436eff;
$color-accent-darker: #103be6;
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #607d8b;
$color-shade-4: #455a64;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;
